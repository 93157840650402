import { useEffect, useState } from 'react';
import { ReactElement } from 'react';
import { useLocalization } from '../../ContextProviders/LocalizationContext';
import { faCheck, faCloudArrowUp, faSave, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { IconButton } from '../../Buttons/Buttons';
import ImagePicker from './ImageSelectorCategory';
import { toasts } from '../../../shared';
import { useConcreteProject } from '../../ContextProviders/ProjectContext';
import { CategoryTypes } from '../../../localizedStrings';
import './CategoriesPage.scss';
import { Category, ChiefCategory, ExternalContent } from '../../../Types';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  nestedModalOpen: boolean;
  setNestedModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  category: Category | ExternalContent | ChiefCategory | null;
  onEdit: (
    category: Category | ExternalContent | ChiefCategory,
    newName: string,
    icon: File | null,
    availableMobile: boolean,
    availableWeb: boolean,
    url: string,
  ) => Promise<void>;
  onSave: (
    name: string,
    icon: File | null,
    type: CategoryTypes,
    availableMobile: boolean,
    availableWeb: boolean,
    adminOnly: boolean,
    url: string,
    cost: string,
  ) => Promise<void>;
  hasChangelog: boolean;
  closeAll: () => void;
  loading: boolean;
}

enum CategoryPrice {
  articleCategory = 4000,
  url = 4000,
  html = 7000,
  workorder = 4000,
  changelog = 5000,
}

const AddCategory = (props: Props): ReactElement => {
  // Strings
  const localization = useLocalization();
  const project = useConcreteProject();
  const categoryTypeList = localization.strings.category.categoryNames;
  const categoryDescriptions = localization.strings.category.categoryDescription;
  // Attributes
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [icon, setIcon] = useState<File | null>(null);
  const [categoryType, setCategoryType] = useState<[CategoryTypes, string]>([
    'articleCategory',
    categoryTypeList.articleCategory,
  ]);
  const [availableMobile, setMobileAvailability] = useState(true);
  const [availableWeb, setWebAvailability] = useState(true);
  const [adminOnly, setAdminOnly] = useState(false);
  const [isPickerVisible, setPickerVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  console.log(project.firebase.project);
  useEffect(() => {
    if (categoryType[0] === 'url' || categoryType[0] === 'nativeModule') {
      setWebAvailability(false);
    } else {
      setWebAvailability(true);
    }
    if (categoryType[0] !== 'articleCategory' && categoryType[0] !== 'url') setAdminOnly(false);
  }, [categoryType]);

  useEffect(() => {
    if (props.category) {
      setName(props.category.name);
      setUrl(props.category.url ?? '');
      setCategoryType([props.category.type as CategoryTypes, categoryTypeList[props.category.type as CategoryTypes]]);
      setWebAvailability(props.category.availableWeb);
      setMobileAvailability(props.category.availableMobile);
    }
  }, [props.category, categoryTypeList]);

  async function fetchImageAsFile(imageUrl: string): Promise<File> {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const uuid = uuidv4();
    const filename = `${uuid}.png`;

    return new File([blob], filename, { type: 'image/png' });
  }

  const handleSelect = async (image: string) => {
    const file = await fetchImageAsFile(image);
    setIcon(file);
    setPickerVisible(false);
  };

  function validateForm(): boolean {
    //Checks so that an icon is selected and a name is provided.
    if (!name || (props.category === null && !icon)) {
      toasts.error(localization.strings.settings.categoryNameAndIconNeeded);
      return false;
    }

    if (categoryType[0] === 'url' && !url) {
      toasts.error(localization.strings.settings.provideUrl);
      return false;
    }

    //Checks so that name doesn't consist of special characters.
    const validNameRegex = /^(?=.*[A-Za-zåäöÅÄÖ0-9])[A-Za-zåäöÅÄÖ0-9 ]*$/;
    if (!validNameRegex.test(name)) {
      toasts.error(localization.strings.settings.categoryNameWrongFormat);
      return false;
    }

    if (name === 'changelog' && categoryType[0] !== 'changelog') {
      toasts.error(localization.strings.settings.idChangelogCantBeUsed);
      return false;
    }
    if (
      props.category &&
      props.category.type === 'url' &&
      name === props.category.name &&
      !icon &&
      props.category.availableMobile === availableMobile &&
      url === props.category.url
    ) {
      toasts.error(localization.strings.settings.noChangesMade);
      return false;
    }
    if (
      props.category &&
      name === props.category.name &&
      !icon &&
      props.category.availableMobile === availableMobile &&
      props.category.availableWeb === availableWeb
    ) {
      toasts.error(localization.strings.settings.noChangesMade);
      return false;
    }
    return true;
  }

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setIcon(files[0]);
    }
  };

  const handleFileChange = (file: File) => {
    if (file) {
      setIcon(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileChange(file);
  };

  const handleSave = () => {
    props
      .onSave(
        name,
        icon,
        categoryType[0],
        availableMobile,
        availableWeb,
        adminOnly,
        url,
        String(CategoryPrice[categoryType[0]]),
      )
      .then(() => {
        ('');
      })
      .catch(() => {
        ('');
      });
  };

  const handleEdit = () => {
    if (!props.category) return;

    props
      .onEdit(props.category, name, icon, availableMobile, availableWeb, url)
      .then(() => {
        ('');
      })
      .catch(() => {
        ('');
      });
  };

  const handleConfirm = () => {
    if (project.firebase.project === 'infosynk-sameffekt') {
      handleSave();
    } else {
      props.setNestedModalOpen(true);
    }
  };

  const clearStateAndClose = () => {
    setIcon(null);
    setName('');
    setUrl('');
    setAdminOnly(false);
    setCategoryType(['articleCategory', categoryTypeList.articleCategory]);
    setWebAvailability(true);
    setMobileAvailability(true);
    props.closeAll();
  };

  useEffect(() => {
    if (!props.modalOpen) {
      clearStateAndClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalOpen]);

  return (
    <Modal isOpen={props.modalOpen} toggle={() => clearStateAndClose()}>
      <ModalHeader toggle={() => clearStateAndClose()}>
        {localization.strings.global.add} {localization.strings.category.category}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="categoryName">{localization.strings.global.name}:</Label>
            <Input
              id="categoryName"
              name="categoryName"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="categoryType">{localization.strings.settings.typeOfCategory}:</Label>

            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} direction={'down'} disabled={!!props.category}>
              <DropdownToggle caret>{categoryType[1]}</DropdownToggle>
              <DropdownMenu>
                {Object.entries(categoryTypeList).map(([key, val], index) => {
                  return (key === 'changelog' && props.hasChangelog) ||
                    (key === 'nativeModule' && !project.enableNativeModules) ? null : (
                    <DropdownItem
                      toggle
                      key={`${index}_${key}`}
                      style={{ margin: '0' }}
                      onClick={() => {
                        setCategoryType([key as CategoryTypes, val]);
                      }}
                    >
                      {val}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </FormGroup>
          <FormGroup>
            <p>{categoryDescriptions[categoryType[0]]}</p>
          </FormGroup>
          {categoryType[0] === 'url' && (
            <FormGroup>
              <Label for="categoryUrl">{localization.strings.global.url}:</Label>
              <Input
                id="categoryUrl"
                name="categoryUrl"
                type="text"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
            </FormGroup>
          )}
          <FormGroup>
            <Label>{localization.strings.global.icon}:</Label>
            <div className="image-upload-container" onDragOver={handleDragOver} onDrop={handleDrop}>
              {icon && <img src={URL.createObjectURL(icon)} alt="Uploaded by user, used as icon" />}

              {!icon && <FontAwesomeIcon icon={faCloudArrowUp} className="upload-icon fa-2xl" />}
              {!icon && <p>{localization.strings.category.uploadCategoryIcon}</p>}

              <Label for="file-upload" className="file-upload-button ">
                {localization.strings.forms.uploadImage}
              </Label>
              <input
                id="file-upload"
                type="file"
                accept="image/*"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
              />
              <Button className="file-upload-button " onClick={() => setPickerVisible(true)}>
                {localization.strings.forms.gallerySelect}
              </Button>
              <ImagePicker
                isVisible={isPickerVisible}
                onClose={() => setPickerVisible(false)}
                onSelect={handleSelect}
              />
            </div>
          </FormGroup>

          <Label>{localization.strings.global.availableOn}:</Label>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
            <FormGroup check>
              <Input
                id="mobileCheckbox"
                name="check"
                type="checkbox"
                checked={availableMobile}
                onChange={() => setMobileAvailability(!availableMobile)}
              />
              <Label check for="mobileCheckbox">
                {localization.strings.global.mobile}
              </Label>
            </FormGroup>
            {categoryType[0] !== 'url' && categoryType[0] !== 'nativeModule' && (
              <FormGroup check>
                <Input
                  id="websiteCheckBox"
                  name="check"
                  type="checkbox"
                  checked={availableWeb}
                  onChange={() => setWebAvailability(!availableWeb)}
                />
                <Label check for="websiteCheckBox">
                  {localization.strings.global.website}
                </Label>
              </FormGroup>
            )}
          </div>

          {props.category === null && (categoryType[0] === 'articleCategory' || categoryType[0] === 'url') && (
            <FormGroup check inline style={{ marginTop: '1rem' }}>
              <Input
                id="adminAccountAddCategory"
                name="check"
                type="checkbox"
                checked={adminOnly}
                onChange={() => setAdminOnly(!adminOnly)}
              />

              <Label check for="adminAccountAddCategory">
                {localization.strings.auth.adminAccountAddCategory} {localization.strings.global.only}
              </Label>
            </FormGroup>
          )}
        </Form>
        <Modal isOpen={props.nestedModalOpen} toggle={() => props.setNestedModalOpen((prev) => !prev)}>
          <ModalHeader>{localization.strings.global.termsOfUse}</ModalHeader>
          <ModalBody>
            <p style={{ whiteSpace: 'pre-line' }}>
              {localization.strings.category.termsOfUseAddCategory.replace(
                '{{value}}',
                String(CategoryPrice[categoryType[0]]),
              )}
            </p>
          </ModalBody>
          <ModalFooter>
            <IconButton
              isLoading={props.loading}
              disabled={props.loading}
              type="button"
              theme="dark"
              onClick={handleSave}
              icon={faCheck}
              text={localization.strings.global.confirm}
            />
            <IconButton
              isLoading={props.loading}
              disabled={props.loading}
              type="button"
              theme="dark"
              onClick={() => props.setNestedModalOpen((prev) => !prev)}
              icon={faXmark}
              text={localization.strings.global.cancel}
            />
          </ModalFooter>
        </Modal>
      </ModalBody>
      <ModalFooter>
        <IconButton
          isLoading={props.loading}
          disabled={props.loading}
          block
          type="button"
          theme="dark"
          onClick={() => {
            if (validateForm()) {
              if (props.category) {
                handleEdit();
              } else {
                handleConfirm();
              }
            }
          }}
          icon={faSave}
          text={localization.strings.global.save}
        />
        <IconButton
          isLoading={props.loading}
          disabled={props.loading}
          block
          type="button"
          theme="dark"
          onClick={clearStateAndClose}
          icon={faXmark}
          text={localization.strings.global.clear}
        />
      </ModalFooter>
    </Modal>
  );
};

export default AddCategory;
